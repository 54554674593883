@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/screenSizes";

.header {
  position: fixed;
  z-index: 25;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--header-height);
  padding: 8px 24px;
  background-color: var(--color-background-light);

  gap: 16px;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  max-height: var(--header-height);
  padding: 4px 0;

  & > picture {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 100%;

    & > img {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: auto;
    }
  }
}

.buttons {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: auto;

  gap: 15px;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;

  & > span {
    @extend %text-body-medium;
  }

  button {
    width: 100px;
    padding: 0;

    & > svg {
      fill: none;
      display: none;
    }
  }
}

@media screen and (max-width: $tabletScreen) {
  .buttonWrapper {
    & > span {
      display: none;
    }
  }
}

@media screen and (max-width: $mobileScreen) {
  .wrapper {
    padding: 16px;
  }

  .logo {
    padding: 0;
  }

  .buttons {
    gap: 10px;
  }

  .buttonWrapper {
    button {
      @include icon-size(36px);

      & > svg {
        display: inline;
      }

      & > span {
        display: none;
      }
    }
  }
}
