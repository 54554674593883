@import './styles/colors';
@import './styles/fonts';
@import './styles/boxShadows';
@import './styles/screenSizes';
@import './styles/customRsuiteStyles';
@import "~react-datepicker/dist/react-datepicker";
@import "~react-grid-layout/css/styles";
@import "~react-resizable/css/styles";

:root {
  --app-height: 100%;
  --header-height: 60px;
  --modal-window-paddings: 30px;
}

* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
}

button {
  font-weight: inherit;
  padding: 0;
  cursor: pointer;
  color: inherit;
  border: none;
  background-color: transparent;
  &:disabled {
    cursor: not-allowed;
  }
}

input {
  border: none;
}

a {
  color: inherit;
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
}

svg {
  fill: currentColor;
}

b {
  font-weight: 500;
}

@media screen and (max-width: $mobileScreen) {
  :root {
    --header-height: 60px;
  }
}
