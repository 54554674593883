@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/screenSizes";
@import "src/styles/buttonEffect";

%dropdownOption {
  display: flex;
  align-items: center;
  width: 100%;

  gap: 10px;

  @extend %text-body-medium;
}

.menuDropdown {
  position: relative;

  @extend %text-body-small;
}

.button {
  position: relative;
  padding: 0;
  height: 25px;
  width: 25px;
}

.buttonIcon {
  flex: 0 0 auto;
  transition: transform .2s;
  transform: rotate(0deg);
  transform-origin: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;

  &Rotate {
    transform: rotate(-90deg);
  }
}

.dropdown {
  z-index: 30;
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  padding: 15px 0;
  border-radius: 20px;
  background: var(--color-background-light);
  box-shadow: var(--box-shadow-light);

  gap: 10px;

  &.up {
    bottom: 16px;
  }

  &.down {
    top: 25px;
  }

  &.left {
    right: 0;

  }

  &.right {
    left: 16px;
  }
}

.option {
  position: relative;
  cursor: pointer;
  transition: all .2s;
  text-align: start;
  padding: 0 15px;

  @extend %dropdownOption;

  &:disabled {
    opacity: .5;
  }

  &.mobile {
    display: none;
  }

  &.tablet {
    display: none;

    & + .divider {
      display: none;
    }
  }

  &.desktop {
    display: flex;
  }

  &.default {
    color: var(--color-font-secondary);
  }

  &.danger {
    color: var(--color-red);
  }
}

.icon {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;

  & > svg {
    @include icon-size(20px);
  }
}

.label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.noOptions {
  @extend %dropdownOption;
}

.divider {
  width: 85%;
  height: 1px;
  background: var(--color-border-light);
  margin: 0 auto;
  padding: 0 15px;

  &:last-of-type {
    display: none;
  }
}

@media screen and (max-width: $tabletScreen) {
  .option {
    &.mobile {
      display: flex;
    }

    &.tablet {
      display: flex;

      & + .divider {
        display: initial;
      }
    }

    &.desktop {
      display: none;
    }
  }
}


@container (max-width: 991px) {
  .option {
    &.mobile {
      display: flex;
    }

    &.tablet {
      display: flex;

      & + .divider {
        display: initial;
      }
    }

    &.desktop {
      display: none;
    }
  }
}
