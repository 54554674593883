@import "src/styles/textStyles";

.wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;

  color: var(--color-font-secondary);
}

.visibilitySection {
  display: flex;

  gap: 10px;

  @extend %text-body-small;
}

.authorName {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 4px;
}

.updateSection {
  margin-top: auto;

  @extend %text-body-small;
}
