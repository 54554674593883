@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/textEllipsis";
@import "src/styles/screenSizes";

.profile {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 50%;
}

.profileButton {
  padding: 0;

  @include icon-size(32px);
}

.dropDown {
  position: absolute;
  right: 0;
  bottom: -5px;
  width: 300px;
  padding: 10px;
  transform: translate(0, 100%);
  border-radius: 20px;
  background-color: var(--color-background-light);
  box-shadow: var(--box-shadow-light);
}

.userInfo {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 10px;

  gap: 10px;
}

.signature {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 50%;
  background-color: var(--color-background-light);
  color: var(--color-font-dark);
  flex: 0 0 auto;
  border: 1px solid var(--color-border-dark);

  @include icon-size(36px);
  @extend %text-body-medium;
}

.userInfoName {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.userEmail {
  overflow: hidden;
  flex: 1 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--color-font-secondary);

  @extend %text-body-medium;
}

.userFullName {
  overflow: hidden;
  flex: 1 1;
  white-space: nowrap;
  text-overflow: ellipsis;

  @extend %text-heading-small;
}

.options {
  display: flex;
  flex-direction: column;

  gap: 10px;
}

.option {
  display: flex;
  align-items: center;
  padding: 0 10px;

  gap: 10px;

  & > svg {
    @include icon-size(20px);
  }

  & > span {
    flex: 1 1;
    text-align: left;

    @extend %text-ellipsis;
    @extend %text-body-medium;
  }
}

.divider {
  height: 0;
  border: 0;
  border-top: 1px solid var(--color-background-secondary-light);
}

@media screen and (max-width: $mobileScreen) {
  .signature,
  .profileButton {
    @include icon-size(35px);
  }
}

