@import "src/styles/textStyles";

.aui-root {
  background-color: var(--color-background-secondary-light);
  color: var(--color-font-dark);
}

.aui-avatar-fallback {
  background-color: var(--color-background-light);
}

.aui-thread-root {
  flex: 1 1;
  overflow: auto;
}

.aui-thread-welcome-root {
  height: 100%;
}

.aui-thread-welcome-suggestions {
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 8px;
}

.aui-thread-welcome-suggestion {
  background-color: var(--color-background-light);
  border-radius: 30px;
  flex: 0 0 auto;
  padding: 8px 32px;
  max-width: 100%;

  &:hover {
    background-color: var(--color-background-light);
    outline: 1px solid var(--color-border-light);
  }
}

.aui-thread-welcome-suggestion-text {
  font-size: 14px;
  font-weight: 400;
}

.aui-thread-welcome-message {
  text-align: center;
  font-size: 23px;
}

.aui-thread-welcome-submessage {
  margin-top: 0.5rem;
  color: var(--color-font-secondary);
  font-weight: 400;
  font-size: 14px;

  @extend %text-body-medium;
}

.aui-composer-root {
  flex-wrap: nowrap;
  background-color: var(--color-background-light);
  border: none;
  border-radius: 30px;

  &:focus {
    outline: 1px solid var(--color-border-light);
  }
}

.aui-composer-input {
  font-size: 15px;

  &::placeholder {
    color: var(--color-font-disabled);
  }
}

.aui-composer-send {
  & > svg {
    fill: transparent;
  }
}

.aui-composer-cancel {
  background-color: transparent;
  box-shadow: none;

  & > svg {
    color: var(--color-font-dark)
  }
}

.aui-user-message-content {
  border-radius: 20px;
  background-color: var(--color-brand);
  color: var(--color-font-dark);
  padding: 8px 12px;
}

.aui-assistant-message-content {
  border-radius: 20px;
  background-color: var(--color-background-light);
  color: var(--color-font-dark);
  padding: 8px 12px;
  margin: 0;
  position: relative;
  max-width: 100%;

  &:empty::after {
    content: "";
    display: block;
    width: 100%;
    height: 16px;
    margin: 6px 0;
    background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
    border-radius: 4px;
  }
}

.aui-text {
  font-size: 16px;
  line-height: 24px;
}

.aui-avatar-root {
  border-radius: 0;
  background-color: transparent;
  padding: 4px;
  width: auto;
  height: 3rem;
}

.aui-avatar-image {
  aspect-ratio: auto;
}

.aui-thread-viewport {
  padding-top: 0;
}

.aui-thread-scroll-to-bottom {
  background: white;
}

.aui-thread-followup-suggestions {
  &:empty {
    display: none;
  }
}

.aui-assistant-action-bar-root {
  margin-left: 5px;
  margin-top: 8px;

  & > button {
    & > svg {
      height: 16px;
      width: 16px;
    }

    &:disabled {
      display: none;
    }
  }

  &:empty {
    display: none;
  }
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
