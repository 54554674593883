.wrapper {
  position: relative;
  display: flex;
  flex: 1 1;
  max-width: 100%;
  height: calc(100vh - var(--header-height));
}

.chatPanel {
  overflow: auto;
  height: 100%;
  display: flex;
}

.dashboardPanel {
  overflow: auto;
  height: 100%;
}

.resizeHandler {
  position: relative;
  width: 8px;
  background-color: var(--color-background-secondary-extralight);

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 7%;
    width: 25%;
    border-radius: 5px;
    background-color: var(--color-background-secondary-dark);
  }
}
