@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/screenSizes";
@import "src/styles/spinAnimation";

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  z-index: 10;

  gap: 15px;
}

.headWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding-left: 16px;

  gap: 20px;
}

.nameWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 2 1;
  max-width: 100%;
  min-width: 0;

  gap: 8px;
}

.name {
  overflow: hidden;
  margin: 0;
  min-width: 0;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;

  @extend %text-heading-extra-large;
}

.infoWrapper {
  position: absolute;
  left: 0;
  top: 64px;
  height: 40px;
  margin-right: auto;
  padding-left: 16px;
}

.lockIcon {
  color: var(--color-font-secondary);

  @include icon-size(20px);
}

@container dashboard-container (max-width: 991px) {
  .nameWrapper {
    flex: 15 1;
  }

  .headWrapper:before {
    flex: 1 1;
  }
}

@container dashboard-container (max-width: 768px) {
  .infoWrapper {
    position: initial;
  }
}
