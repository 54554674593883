@import "src/styles/iconSize";
@import "src/styles/screenSizes";
@import "src/styles/selectStyles";

.agentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.collapsedWrapper {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  svg {
    fill: transparent;
  }
}

.threadList {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  max-width: 44rem;
  width: 100%;
  margin: 0 auto;

  gap: 12px;
}

.addThread {
  padding: 0;

  svg {
    fill: transparent;
  }
}

.closeChat {
  padding: 0;

  svg {
    fill: transparent;
  }
}

.chatHistory {
  padding: 0;

  & > svg {
    fill: transparent;
  }
}

.closeChatWrapper {
  margin-left: auto;
}

.select {
  width: auto;
  border: none;
  background: transparent;
}

.button {
  padding: 0;
  width: 100%;
  color: var(--color-red);

  @extend %text-body-medium;

  svg {
    @include icon-size(15px);
  }
}

.dropdown {
  max-width: 300px;
}

@media screen and (max-width: $mobileScreen) {
  .dropdown {
    min-width: 100%;
  }
}
