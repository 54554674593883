@import "src/styles/screenSizes";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: sticky;
  top: 0;
  z-index: 5;
  margin-left: auto;

  gap: 15px;
}

@media screen and (max-width: $mobileScreen) {
  .wrapper {
    width: 100%;

    & > div {
      width: 100%;

      & > * {
        flex: 1;
      }
    }
  }
}
