@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/screenSizes";
@import "src/styles/buttonEffect";

.wrapper {
  position: relative;
  height: 40px;
}

.inputWrapper {
  width: 100%;
  position: relative;
  cursor: pointer;

  &:has(input:disabled) {
    opacity: .5;
    cursor: not-allowed;
  }

  input {
    cursor: pointer;
  }
}

.input {
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent;
  padding: 0 30px 0 15px;
  pointer-events: none;

  &:focus {
    overflow: hidden;
  }

  &:read-only:not(:disabled) {
    background-color: transparent;
    opacity: 1;
  }
}

.triangle {
  position: absolute;
  top: 50%;
  right: 10px;
  transition: all .2s;
  transform: translateY(-50%);

  @include icon-size(20px);
}

.label,
.placeholder {
  line-height: 40px;
  display: flex;
  overflow: hidden;
  align-items: center;
  width: calc(100% - 44px);
  padding: 0 16px;
  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    min-width: 18px;
    margin: 0 5px;
  }
}

.placeholder {
  color: var(--color-font-secondary);
}

.arrowIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;

  & > * {
    transition: all .2s;
  }
}

.popup {
  position: absolute;
  z-index: 30;
  right: 0;
  left: 0;
  padding: 16px;
  max-width: 400px;
  min-width: 100%;
  display: flex;
  border-radius: 20px;
  background-color: var(--color-background-light);
  flex-direction: column;
  box-shadow: var(--box-shadow-light);

  gap: 10px;

  &.top-start {
    bottom: 45px;
    left: auto;
    right: 0
  }

  &.top {
    bottom: 45px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &.top-end {
    bottom: 45px;
    left: 0;
    right: auto;
  }

  &.bottom-start {
    top: 45px;
    left: auto;
    right: 0
  }

  &.bottom {
    top: 45px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &.bottom-end {
    top: 45px;
    left: 0;
    right: auto;
  }
}

.options {
  overflow-y: auto;
  max-height: 200px;
  cursor: pointer;
  margin: 0;
  padding: 0;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
}

%option {
  line-height: 24px;
  overflow: hidden;
  height: 24px;
  padding: 0 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  color: var(--color-font-secondary);

  @extend %text-body-medium;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  transition: all .2s;

  gap: 5px;

  @extend %option;

  &Selected {
    font-weight: 500;
  }

  &:hover > .optionActions {
    display: flex;

  }
}

.optionIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  svg {
    @include icon-size(20px);
  }
}

.optionLabel {
  flex: 1 1 auto;
  width: 100%;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.optionActions {
  display: none;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;
  margin-left: auto;

  gap: 5px;
}

.noOptions {
  display: flex;
  justify-content: center;
  align-items: center;

  @extend %option;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-border-light);
}

.popupFooter {
  color: var(--color-font-secondary);
}

.unselectButton {
  position: absolute;
  top: 50%;
  right: 10px;
  transition: all .2s;
  transform: translateY(-50%);
  padding: 0 !important;

  & > svg {
    height: 16px !important;
    width: 16px !important;
    min-height: 16px !important;
    min-width: 16px !important;
  }
}

@media screen and (max-width: $mobileScreen) {
  .popup {
    min-width: auto;
  }
}
